import React from "react";
import { store } from "../store/store";
import { setSettingsAction } from "../store/main.slice";
import { getEmbeddFromUrl } from "../Helpers/utils";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OneCategoryDetail = ({ item, apiKey }) => {
  let navigate = useNavigate();

  const handleSelectItem = (item) => {
    navigate("../" + item._id);
    store.dispatch(
      setSettingsAction({ isItemDialogOpen: true, selectedItem: item })
    );
  };

  // onError={(e) => {
  //   e.target.src = "../loading-load.gif";
  // }}
  // loading="lazy"
  return (
    <div
      className="image-item"
      key={item._id}
      onClick={(e) => handleSelectItem(item)}
    >
      <LazyLoadImage
        className="img-element"
        alt={"../loading-load.gif"}
        height={300}
        onLoad={(e) => {
          // console.log("onLoad", e);
        }}
        onError={(e) => {
          // console.log(e);
          e.target.src = "../loading-load.gif";
        }}
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: { transitionDelay: "0.3s" },
        }}
        src={getEmbeddFromUrl(item.photos[0], apiKey)}
        width={300}
      />
      <div className="overlay">
        <span>{item.name}</span>
      </div>
    </div>
  );
};

export default OneCategoryDetail;
