import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OneCategoryDetail from "../components/OneCategoryDetail";
import { GetSubCategoriesDetails } from "../proxy/http.proxy";
import { GetSubCategoriesDetailsSelector } from "../store/main.selector";
import homeJsonData from "../configuration/home.config.json";
const CategoriesDetails = ({ subCategoryId }) => {
  const [subCategoriesStateDetails, setSubCategoriesStateDetails] = useState(
    []
  );
  const subCategoriesDetails = useSelector(GetSubCategoriesDetailsSelector);

  const data = {
    gkey: homeJsonData.ApiKey,
    dirId: "1qbddybwwNkU_08YWQ4zTrjaY4P5ckhBf",
    name: "name1",
    options: {
      hover: true,
    },
  };

  useEffect(() => {
    if (
      subCategoriesDetails != undefined &&
      Array.isArray(subCategoriesDetails)
    ) {
      setSubCategoriesStateDetails(subCategoriesDetails);
    }
  }, [subCategoriesDetails]);

  useEffect(() => {
    if (subCategoryId != undefined) {
      GetSubCategoriesDetails(subCategoryId);
    }
  }, [subCategoryId]);

  return (
    <div className="container">
      {subCategoryId && (
        <div>
          <div className="image-gallery">
            <div className="column">
              {/* <GDImageViewer data={data} /> */}
              {subCategoriesStateDetails &&
                subCategoriesStateDetails.map((item, index) => (
                  <OneCategoryDetail
                    item={item}
                    key={index}
                    apiKey={homeJsonData.ApiKey}
                  />
                ))}
            </div>
          </div>
          {/* <CategoriesDetailsDialog /> */}
        </div>
      )}
    </div>
  );
};

export default CategoriesDetails;
